<template>
  <div>
    <el-tabs>
      <el-tab-pane label="基础信息">
        <el-card style="min-height: 45rem">
          <el-form label-position="left">
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="服务分类" class="flex-def">
                  <CateChoose v-model="form.cate_id"></CateChoose>
                </el-form-item>
                <el-form-item label="卡券名称" class="flex-def">
                  <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="首页推荐" class="flex-def">
                  <el-switch v-model="form.recommend"></el-switch>
                </el-form-item>
                <el-form-item label="卡券面值">
                  <el-input-number v-model="form.fee"></el-input-number>元
                </el-form-item>
                <el-form-item label="可用次数">
                  <el-input-number v-model="form.total_use"></el-input-number>次
                </el-form-item>
                <el-form-item label="使用限制">
                  两次使用间隔
                  <el-input-number v-model="form.use_limit"></el-input-number>
                  单位
                  <el-radio-group v-model="form.use_limit_type">
                    <el-radio :label="0">天</el-radio>
                    <el-radio :label="1">时</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="使用门栏" class="flex-def">
                  <el-input v-model="form.tips"></el-input>
                </el-form-item>
                <el-form-item label="缩略图" class="flex-def">
                  <y_upload_img v-model="form.pic"></y_upload_img>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="卡券详情">
                  <br>
                  <y_editor v-model="form.detail"></y_editor>
                </el-form-item>
                <el-form-item>
                  <el-button @click="edit" type="primary">保存</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="轮播图">
        <el-card>
          <BannerEdit @save="edit" v-model="form.banner"></BannerEdit>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import CateChoose from "@/components/card/Merchant/CateChoose";
import BannerEdit from "@/components/card/Merchant/BannerEdit";
import y_editor from "@/components/y-form/y-editor/y_editor";
export default {
  name: "edit",
  components: {
    CateChoose,
    y_upload_img,
    BannerEdit,
    y_editor,
  },
  data() {
    return {
      form: {
        title: "",
        fee: 0,
        cate_id: 0,
        total_use: 0,
        use_limit: 0,
        tips: "",
        pic: "",
        banner: "",
        detail: "",

        use_limit_type:0,
        recommend:false,
      },
      edit_id:0,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  computed:{

  },
  methods: {
    load() {
      this.$u.api.card.merchantCouponOne({id:this.edit_id}).then(res => {
        this.form = res;
      })
    },
    edit() {
      this.form.merchant_id = parseInt(this.$route.query.merchant_id);
      this.$u.api.card.merchantCouponEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
  }
}
</script>

<style scoped>

</style>
